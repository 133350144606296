<template>
  <div class="a2p-page">
    <div class="card">
      <div class="card-body">
        <h5 class="h5">SMS Registration</h5>
        <div v-if="loading" class="text-center">
          <b-spinner></b-spinner>
        </div>
        <div v-else class="mt-5">
          <div v-if="brandStatus === 'unverified'">
            <h5 class="h5 text-primary d-flex align-items-center"><strong class="mx-2 text-danger"><i>Decline -
                  Brand</i></strong>
            </h5>
            <div class="mt-4 alert alert-danger">
              <h5 class="text-white">Sorry!</h5>
              <p>The business information is inaccurate for brand approval. Please review the business information to make
                sure it is accurate and resubmit for approval. If the information you already submitted is accurate or you
                have questions send in a ticket to <a :href="`mailto:${agencyEmail}`"
                  class="text-white font-weight-bold"><u>{{ agencyEmail }}</u></a></p>
            </div>
          </div>
          <div v-else-if="brandStatus" class="mt-5">
            <h5 class="h5 d-flex align-items-center"><strong :class="{ 'text-danger': isCampaignFailed }"
                class="mx-2 text-primary"><i>{{ campaignStatusStr
                  | capitalize }}</i></strong><b-spinner v-if="campaignInProgress" class="text-primary"></b-spinner></h5>

            <div v-if="campaignInProgress" class="mt-4 alert alert-primary">
              <h5 class="text-white">Great Job!</h5>
              <p>SMS registration is under review, and may take <b>7 ~ 10 business days</b> to complete. You may check
                back on this registration for the status.</p>
            </div>
            <div v-if="campaignStatus === 'completed'" class="mt-4 alert alert-primary">
              <h5 class="text-white">Congratulations!</h5>
              <p>Your SMS registration is completed and you can send text messages to your customers.</p>
            </div>
            <div v-if="isCampaignDeclined" class="mt-4 alert alert-danger">
              <h5 class="text-white">Sorry!</h5>
              <p v-if="dcaReason">{{ dcaReason }}</p>
              <p v-else>There's something wrong in SMS registration. Please contact <a :href="`mailto:${agencyEmail}`"
                  class="text-white font-weight-bold"><u>{{ agencyEmail }}</u></a> for further assistance.</p>
            </div>
            <div v-if="campaignStatus === 'expired'" class="mt-4 alert alert-danger">
              <h5 class="text-white">Sorry!</h5>
              <p>You can no longer send SMS messages. To start sending messages again please contact <a
                  :href="`mailto:${agencyEmail}`" class="text-white font-weight-bold"><u>{{ agencyEmail }}</u></a>.</p>
            </div>
          </div>
          <div class="mt-5">
            <h5 class="font-weight-bold">What's your company information?</h5>
            <span class="d-block">Your information will only be used to verify business phone number so that you can send
              SMS messages in
              the United States and Canada.</span>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="brandForm">
            <form @submit.prevent="handleSubmit(onRegisterBrand)">
              <div class="row mt-5">
                <div class="col-md-6">
                  <TextInput v-model="data.brand.name" name="name" rules="required" label="DBA or Brand Name" sublabel="*"
                    :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ failed, errors }" name="sms_org_form" rules="required">
                    <label>What type of legal form is the org? <span class="text-danger">*</span></label>
                    <multiselect v-model="orgForm" :allow-empty="true" :options="orgForms" :multiple="false"
                      :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                      :disabled="isFormDisabled"></multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.company_name" name="company_name" rules="required"
                    label="Legal Company Name" sublabel="*" :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.ein" name="ein" rules="required" label="Tax Number/ID/EIN" sublabel="*"
                    :disabled="isFormDisabled" @changed="onEinChange" />
                </div>
                <div class="col-md-6">
                  <PhoneInput v-model="data.brand.phone" name="phone" label="Phone" sublabel="*" rules="required|phone"
                    :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.email" type="email" name="email" rules="required|email" label="Email"
                    sublabel="*" :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.address" name="address" rules="required" label="Address" sublabel="*"
                    :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.city" name="city" rules="required" label="City" sublabel="*"
                    :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6 mb-3">
                  <ValidationProvider v-slot="{ failed, errors }" name="sms_country" rules="required">
                    <label>Country <span class="text-danger">*</span></label>
                    <multiselect v-model="country" :allow-empty="true" :options="countries" :multiple="false"
                      :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                      :disabled="isFormDisabled" @select="onCountrySelect"></multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ failed, errors }" name="sms_state" rules="required">
                    <label>State <span class="text-danger">*</span></label>
                    <multiselect v-model="state" :allow-empty="true" :options="states" :multiple="false"
                      :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                      :disabled="isFormDisabled"></multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <TextInput v-model="data.brand.zip" name="zip" rules="required" label="Zip Code"
                    sublabel="*" :disabled="isFormDisabled" />
                </div>
                <div class="col-md-6">
                  <ValidationProvider v-slot="{ failed, errors }" name="sms_vertical" rules="required">
                    <label>Vertical Type <span class="text-danger">*</span></label>
                    <multiselect v-model="vertical" :allow-empty="true" :options="verticals" :multiple="false"
                      :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                      :disabled="isFormDisabled"></multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <h5 class="h5">SMS Message Add-ons</h5>
                  <label>Organizational Prefix <span class="text-danger">*</span> <span v-b-tooltip.hover
                      class="cursor-pointer"
                      title="Unlike email, SMS doesn't have a built in way to identify who sends messages. Prefixed will be added to the beginning of your SMS message to clarify your identity."><i
                        class="uil uil-question-circle"></i></span></label>
                  <TextInput v-model="data.organizational_prefix" name="OrganizationalPrefix" rules="required"
                    help="A recognizable prefix will ensure your audience knows who you are." />
                  <b-button variant="primary" :disabled="processing" type="button" class="btn-sm" @click="updatePrefix">
                    <b-spinner v-if="processing" small />
                    <span v-else>Update Prefix</span>
                  </b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-5">
                  <b-form-group class="text-center">
                    <b-button variant="primary" :disabled="processing" type="button" class="mr-2" @click="getA2pRequest">
                      <b-spinner v-if="processing" small />
                      <span v-else>Refresh</span>
                    </b-button>
                    <b-button v-if="canSubmitCampaign" variant="primary" :disabled="processing" type="submit">
                      <b-spinner v-if="processing" small />
                      <span v-else>Submit Information</span>
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <b-modal v-model="showEinModal" title="Confirmation">
          <p>We found a brand with same <b>EIN ( {{ data.brand.ein }} )</b> is already registered. Are you going to use
            existing brand for your registration process?</p>
          <template v-slot:modal-footer>
            <b-button variant="primary" @click="onEinConfirm">Yes</b-button>
            <b-button variant="outline-dark" @click="onEinCancel">No</b-button>
          </template>
        </b-modal>
        <b-modal v-model="showConfirmModal" no-close-on-backdrop hide-header-close>
          <template v-slot:modal-title>
            <h5 class="text-primary">Confirmation</h5>
          </template>
          <p v-if="isNew">By Pressing Submit, You Are Agreeing to the one time fee of <b class="text-primary">$55</b> for
            processing your registration
            then monthly recurring charge of <b class="text-primary">$20</b> for your text messages to continue to go
            through without interruption.
          </p>
          <p v-else>By resubmitting you are agreeing to the one time fee of <b class="text-primary">$15</b> for the
            resubmitting your registration.
          </p>
          <template v-slot:modal-footer>
            <b-button variant="light" @click="showConfirmModal = false">Cancel</b-button>
            <b-button variant="primary" @click="registerBrand">Submit</b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>
          
<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      loading: false,
      processing: false,
      data: {
        brand: {},
      },
      orgForms: [
        { value: 'PRIVATE_PROFIT', label: 'Privately Held Company' },
        { value: 'PUBLIC_PROFIT', label: 'Publicly Traded Company' },
        { value: 'NON_PROFIT', label: 'Nonprofit Organization' },
        { value: 'GOVERNMENT', label: 'Government' },
      ],
      orgForm: undefined,
      country: undefined,
      state: undefined,
      vertical: undefined,
      einData: undefined,
      showEinModal: false,
      useEinData: false,
      isNew: true,
      showConfirmModal: false,
      states: [],
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user', 'agency'
    ]),

    ...mapGetters('business', {
      countries: 'countriesBw',
      statesUs: 'statesBw',
      statesCa: 'statesCaBw',
      verticals: 'verticalsBw',
    }),

    brandStatus() {
      if (this.data && this.data.brand_status === 'in-progress')
        return this.data.brand_status
      return this.data && this.data.brand && this.data.brand.status
    },

    isFormDisabled() {
      return this.brandStatus && !['unverified'].includes(this.brandStatus) && this.campaignStatus !== 'declined' || this.useEinData
    },

    brandInProgress() {
      return this.brandStatus && !['verified', 'unverified'].includes(this.brandStatus)
    },

    campaignStatus() {
      return this.data && this.data.main_status
    },

    dcaReason() {
      return this.data && this.data.dca_reason
    },

    campaignStatusStr() {
      if (this.campaignStatus === 'declined')
        return 'Decline - Campaign'
      else if (this.campaignStatus === 'expired')
        return 'Deactivated'
      else if (this.campaignStatus === 'completed')
        return 'Verified & Approved'
      else
        return this.data && this.data.main_status_str
    },

    canSubmitCampaign() {
      return !(this.brandStatus && !['unverified'].includes(this.brandStatus) && this.campaignStatus !== 'declined')
    },

    campaignInProgress() {
      return this.campaignStatus && !['completed', 'expired', 'declined'].includes(this.campaignStatus)
    },

    isCampaignFailed() {
      return ['expired', 'declined'].includes(this.campaignStatus)
    },

    isCampaignDeclined() {
      return this.campaignStatus === 'declined'
    },

    agencyEmail() {
      return this.agency && this.agency.email;
    },
  },

  watch: {
    'data.brand.org_form': function (val) {
      if (val) {
        this.orgForm = this.orgForms.find(item => item.value === val)
      }
    },
    'data.brand.country': function (val) {
      if (val) {
        this.country = this.countries.find(item => item.value === val)
      }
    },
    'data.brand.state': function (val) {
      if (val) {
        this.state = this.states.find(item => item.value === val)
      }
    },
    'data.brand.vertical': function (val) {
      if (val) {
        this.vertical = this.verticals.find(item => item.value === val)
      }
    },
  },

  mounted() {
    this.states = this.statesUs
    this.getA2pRequest()
  },

  methods: {
    initData() {
      this.useEinData = false
      this.orgForm = this.orgForms.find(item => item.value === this.data.brand.org_form)
      this.country = this.countries.find(item => item.value === this.data.brand.country)
      this.state = this.states.find(item => item.value === this.data.brand.state)
      this.vertical = this.verticals.find(item => item.value === this.data.brand.vertical)
    },

    getA2pRequest() {
      this.loading = true
      this.$store
        .dispatch('business/getA2pRequest')
        .then((data) => {
          if (data.data) {
            this.data = {
              ...data.data
            }
            this.initData()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    onCountrySelect(item, id) {
      if (item && item.value === 'CA') {
        this.states = this.statesCa
      } else {
        this.states = this.statesUs
      }
      this.state = null
    },

    onEinConfirm() {
      this.data.brand = {
        ...this.einData,
        status: this.data.brand.status,
      }
      if (this.einData.status === 'verified') {
        this.useEinData = true
      }
      this.einData = undefined
      this.showEinModal = false
    },

    onEinCancel() {
      this.einData = undefined
      this.useEinData = false
      this.showEinModal = false
      this.data.brand.brand_id = undefined
    },

    onEinChange() {
      this.data.brand.ein = this.data.brand.ein.replace(/[^0-9a-zA-Z ]/g, "")
      if (!this.data.brand.ein) {
        this.data.brand_id = null;
        this.data.brand.brand_id = null;
      } else {
        let params = {
          ein: this.data.brand.ein,
        }
        this.$store
          .dispatch('business/findBrandByEin', params)
          .then((data) => {
            if (data.data.brand_id) {
              this.einData = {
                ...data.data
              }
              this.showEinModal = true
            } else {
              this.data.brand_id = null;
              this.data.brand.brand_id = null;
            }
          })
      }
    },

    updatePrefix() {
      if (!this.data.organizational_prefix) {
        this.$refs.brandForm.setErrors({
          'OrganizationalPrefix': ['OrganizationalPrefix is not valid.']
        })
        return
      }

      this.processing = true;
      let params = {
        organizational_prefix: this.data.organizational_prefix,
      }
      this.$store
        .dispatch('business/updatePrefix', params)
        .then((data) => {
          this.processing = false
        })
    },

    onRegisterBrand() {
      if (this.brandStatus === 'unverified' || this.isCampaignDeclined) {
        this.isNew = false
      }
      this.showConfirmModal = true
    },

    registerBrand() {
      this.showConfirmModal = false

      this.data.brand.org_form = this.orgForm ? this.orgForm.value : null
      this.data.brand.country = this.country ? this.country.value : null
      this.data.brand.state = this.state ? this.state.value : null
      this.data.brand.vertical = this.vertical ? this.vertical.value : null

      let params = {
        ...this.data,
      }

      this.processing = true
      this.$store
        .dispatch('business/registerBrand', params)
        .then((res) => {
          this.processing = false
          this.$store.dispatch('auth/getProfile')
          this.data.brand.status = 'in-progress'
          this.data.main_status = 'in-progress'
          this.data.main_status_str = 'in progress'
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.brandForm.setErrors(err.response.data.errors)
          }
          this.processing = false
        })
    },

    regsiterCampaign() {
      this.processing = true
      this.$store
        .dispatch('business/registerCampaign')
        .then((res) => {
          this.processing = false
          this.$store.dispatch('auth/getProfile')
          this.data.main_status = 'in-progress'
        })
        .catch(() => {
          this.processing = false
        })
    },
  },
}
</script>
          
<style lang="scss">
.a2p-page {
  .multiselect--disabled {
    background-color: #d0d0d0 !important;

    .multiselect__tags,
    .multiselect__single,
    .multiselect__select {
      background-color: #d0d0d0 !important;
    }
  }
}
</style>
    