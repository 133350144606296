<template>
  <div class="settingsPage">
    <div v-if="business" class="row">
      <div class="col-12">
        <b-tabs>
          <b-tab title="Setting" :active="tab === '1'" @click="onTabClick(1)">
            <BusinessInfo />
          </b-tab>
          <b-tab v-if="showNumberVerification" :title="verificationLabel" :active="tab === '2'" @click="onTabClick(2)">
            <TollFree v-if="isTollfree" />
            <Campaign v-else />
          </b-tab>
          <b-tab title="Privacy & Terms" :active="tab === '3'" @click="onTabClick(3)">
            <LegalPage />
          </b-tab>
          <b-tab title="Whitelist" :active="tab === '4'" @click="onTabClick(4)">
            <Whitelist />
          </b-tab>
          <b-tab title="Blacklist" :active="tab === '5'" @click="onTabClick(5)">
            <Blacklist />
          </b-tab>
          <b-tab v-if="!agency" title="Billing" :active="tab === '6'" @click="onTabClick(6)">
            <Billing />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import BusinessInfo from './BusinessInfo'
import LegalPage from './LegalPage'
import Blacklist from './Blacklist'
import Whitelist from './Whitelist'
import Billing from './../Billing/Index'
import TollFree from './TollFree'
import Campaign from './Campaign/Index'

export default {
  components: {
    BusinessInfo, LegalPage, Blacklist, Whitelist, Billing,
    TollFree, Campaign,
  },

  data() {
    return {
      business: {},
      user: {
        business: {},
      },
      tab: 1,
    }
  },

  computed: {
    authUser() {
      return this.$store.getters['auth/user']
    },
    agency() {
      return this.authUser && this.authUser.business && this.authUser.business.agency
    },
    isTollfree() {
      return this.business && this.business.is_tollfree
    },
    showNumberVerification() {
      return this.user.use_bandwidth
    },
    verificationLabel() {
      return 'SMS Registration'
    },
    tabIndex() {
      return this.$route.query.tab
    }
  },

  watch: {
    'authUser.business': function (newVal) {
      if (newVal) {
        this.initData()
      }
    },
    'tabIndex': function(newVal) {
      if (newVal) {
        this.tab = newVal
      }
    }
  },

  mounted() {
    if (this.$route.query.tab)
      this.tab = this.$route.query.tab
    this.initData()
  },

  methods: {
    initData() {
      this.user = Vue.util.extend({}, this.authUser)
      this.business = Vue.util.extend({}, this.user.business)
    },

    onTabClick(index) {
      this.tab = index
      this.$router.push({ name: 'business.settings', query: {tab: index} })
    }
  },
}
</script>
