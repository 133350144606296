<template>
  <div v-if="legalPages.length" class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h5 class="h5">Privacy Policy</h5>
        <div class="form-group">
          <editor v-model="legalPages[0].content" :api-key="tinyMCEKey" :init="{ height: 400 }"
            :toolbar="customToolbar" :plugins="plugins" />
        </div>
        <h5 class="h5">Terms Of Service</h5>
        <div class="form-group">
          <editor v-model="legalPages[1].content" :api-key="tinyMCEKey" :init="{ height: 400 }"
            :toolbar="customToolbar" :plugins="plugins" />
        </div>
        <button class="ml-2 btn btn-primary" :disabled="loadingSavePage" @click="handleSaveLegalPage">
          <b-spinner v-if="loadingSavePage" small />
          <span v-else><i class="uil uil-upload"></i> Save</span>
        </button>
      </div>
    </div>
  </div>
</template>
    
<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    'editor': Editor,
  },

  data() {
    return {
      tinyMCEKey: process.env.VUE_APP_TINYMCE_KEY,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
      loadingSavePage: false,
      legalPages: [],
    }
  },

  computed: {
  },

  watch: {
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      this.getLegalPages()
    },

    getLegalPages() {
      this.$store
        .dispatch('business/getLegalPages')
        .then((pages) => {
          this.legalPages = pages
        })
        .catch(() => { })
    },

    handleSaveLegalPage() {
      this.loadingSavePage = true

      this.$store.dispatch('business/updateLegalPages', this.legalPages)
        .then((pages) => {
          this.legalPages = pages
          this.loadingSavePage = false
        })
        .catch(() => {
          this.loadingSavePage = false
        })
    },
  },
}
</script>
    