<template>
  <div class="contacts-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-4">
                <div class="dataTables_length d-inline">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100, 'All']"
                      @change="getBlacklists"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="dataTables_filter text-md-right">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="handleNewWhitelist"
                  >
                    <i class="uil uil-plus"></i> New Blacklist
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!isLoadingBlacklists">
              <b-table
                :items="blacklists.data"
                :fields="datatable.columns"
                :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc"
                :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page"
                :no-local-sorting="true"
                @sort-changed="sortChanged"
              >
                <template v-slot:cell(actions)="data">
                  <b-dropdown
                    variant="black"
                    right
                    toggle-class="arrow-none text-muted pr-0"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-v"></i>
                    </template>

                    <b-dropdown-item
                      @click="handleEdit(data.item)"
                    >
                      <i class="uil uil-edit mr-2"></i>Edit
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="loadingRemove === data.item.id"
                      variant="danger"
                      @click="destroy(data.item)"
                    >
                      <i class="uil uil-trash mr-2"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ blacklists.meta.total }} blacklists
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="blacklists.meta.total"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete blacklist">
      <p v-if="selectedBlaclist"
        >Are you sure you want to delete blacklist
        <b>"{{ selectedBlaclist.id }}"</b>?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowNewBlacklistModal" :title="`${selectedBlaclist.id ? 'Edit' : 'New'} Blacklist`">
      <TextInput v-model="selectedBlaclist.ip" label="Ip" />
      <PhoneInput v-model="selectedBlaclist.phone" label="Phone" rules="phone" @onInputChange="handlePhoneChange"/>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowNewBlacklistModal = false"
          >Cancel</b-button
        >
        <b-button variant="success"
          :disabled="!selectedBlaclist.ip && !selectedBlaclist.phone || selectedBlaclist.phone && !isPhoneValid"
          @click="handleUpdateOrCreateBlacklist"
          >
            <b-spinner v-if="isLoadingBlacklist" small></b-spinner>
            <span v-else>{{ selectedBlaclist.id ? 'Update' : 'Create' }}</span>
          </b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#' },
          { key: 'phone', label: 'Phone' },
          { key: 'ip' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'asc',
          keyword: '',
          opt_out: '',
        },
      },
      blacklists: { meta: {}},
      showDeleteModal: false,
      loadingRemove: null,
      isShowNewBlacklistModal: false,
      selectedBlaclist: {},
      isPhoneValid: false,
      isLoadingBlacklist: false,
      isLoadingBlacklists: false,
    }
  },

  mounted() {
    this.getBlacklists()
  },

  methods: {
    handlePhoneChange(args) {
      this.isPhoneValid = args.valid
    },

    handleUpdateOrCreateBlacklist() {
      this.isLoadingBlacklist = true

      this.$store.dispatch(this.selectedBlaclist.id ? 'blacklist/update' : 'blacklist/create', this.selectedBlaclist)
        .then(() => {
          this.getBlacklists()
          this.isLoadingBlacklist = false
          this.isShowNewBlacklistModal = false
        })
        .catch(() => {
          this.isLoadingBlacklist = false
        })
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getBlacklists()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBlacklists()
    },

    getBlacklists() {
      this.isLoadingBlacklists = true

      this.$store
        .dispatch('blacklist/getAll', this.datatable.queries)
        .then((blacklists) => {
          this.blacklists = blacklists
          this.isLoadingBlacklists = false
        })
        .catch(() => {
          this.isLoadingBlacklists = false
        })
    },

    destroy(whitelist) {
      this.showDeleteModal = true
      this.selectedBlaclist = whitelist
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBlaclist.id

      this.$store
        .dispatch('blacklist/remove', this.selectedBlaclist.id)
        .then(() => {
          this.getBlacklists()
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleNewWhitelist() {
      this.selectedBlaclist = {}
      this.isShowNewBlacklistModal = true
    },

    handleEdit(whitelist) {
      this.isPhoneValid = true
      this.selectedBlaclist = {
        ...whitelist
      }
      this.isShowNewBlacklistModal = true
    }
  },
}
</script>

